<template>
    <div class="searchForm">
        <el-form v-model="searchFrom">
            <el-row :gutter="30">
                <el-col :span="6">
                    <el-form-item label="退款订单号：">
                        <el-input v-model="searchFrom.refund_no"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="平台订单号：">
                        <el-input v-model="searchFrom.trade_no"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-button type="primary" @click="search">
                        <el-icon style="color:#fff">
                            <Search />
                        </el-icon>
                        搜索
                    </el-button>

                </el-col>
            </el-row>
        </el-form>
    </div>
    <div class="table">
        <c-table ref="tableRef" :tableDataUrl="tableDataUrl" :border="true" :isLoadData="true">
            <el-table-column type="index" width="55" label="序号" :align="'center'" />
            <el-table-column label="退款订单号" prop="refund_no"></el-table-column>
            <el-table-column label="平台订单号" prop="trade_no"></el-table-column>
            <el-table-column label="商户名称" prop="merchant_name"></el-table-column>
            <el-table-column label="商户号" prop="merchant_no" width="120"></el-table-column>
            <el-table-column label="店铺名称" prop="store_name"></el-table-column>
            <el-table-column label="退款金额" prop="refund_amount" width="100"></el-table-column>
            <el-table-column label="支付方式" prop="payment_method" width="120"></el-table-column>
            <el-table-column label="创建时间" width="200">
                <template #default="scope">
                    {{ timeFormat(scope.row.created_at, 'datetime') }}
                </template>
            </el-table-column>
            <el-table-column label="操作" width="80">
                <template #default="scope">
                    <el-link type="primary" @click="details(scope.row)">详情</el-link>
                </template>
            </el-table-column>
        </c-table>
    </div>
    <el-dialog v-model="isShowDetail" :show-close="false">
        <template #header>
            <span style="font-size:20px;color:#000">订单详情</span>
        </template>
        <el-skeleton v-if="skeleton" :rows="9" :animated='true' :loading='true' />
        <div v-else class="details">
            <el-row justify="space-evenly" :gutter="25" class="row-bg">
                <el-col :span="4" class="col-bg">平台退款号：</el-col>
                <el-col :span="8" class="col-bg">{{ orderDetail.trade_no }}</el-col>
                <el-col :span="4" class="col-bg">渠道退款号：</el-col>
                <el-col :span="8" class="col-bg">{{ orderDetail.channel_refund_no }}</el-col>
            </el-row>
            <el-row justify="space-evenly" :gutter="25" class="row-bg">
                <el-col :span="4" class="col-bg">退款单号：</el-col>
                <el-col :span="8" class="col-bg">{{ orderDetail.refund_no }}</el-col>
                <el-col :span="4" class="col-bg">商户退款号：</el-col>
                <el-col :span="8" class="col-bg">{{ orderDetail.out_refund_no }}</el-col>
            </el-row>
            <el-row justify="space-evenly" :gutter="25" class="row-bg">
                <el-col :span="4" class="col-bg">代理商名称：</el-col>
                <el-col :span="8" class="col-bg">{{ orderDetail.agent_name }}</el-col>
                <el-col :span="4" class="col-bg">门店名称：</el-col>
                <el-col :span="8" class="col-bg">{{ orderDetail.store_name }}</el-col>
            </el-row>
            <el-row justify="space-evenly" :gutter="25" class="row-bg">
                <el-col :span="4" class="col-bg">商户名称：</el-col>
                <el-col :span="8" class="col-bg">{{ orderDetail.merchant_name }}</el-col>
                <el-col :span="4" class="col-bg">商户号：</el-col>
                <el-col :span="8" class="col-bg">{{ orderDetail.merchant_no }}</el-col>
            </el-row>
            <el-row justify="space-evenly" :gutter="25" class="row-bg">
                <el-col :span="4" class="col-bg">退款金额：</el-col>
                <el-col :span="8" class="col-bg"><span style="color:rgb(240,65,85)">{{ orderDetail.refund_amount
                }}</span></el-col>
                <el-col :span="4" class="col-bg">退款状态：</el-col>
                <el-col :span="8" class="col-bg">
                    <span v-if="orderDetail.status == 0">
                        <el-tag type="info">取消退款</el-tag>
                    </span>
                    <span v-if="orderDetail.status == 1">
                        <el-tag type="info">退款处理中</el-tag>
                    </span>
                    <span v-if="orderDetail.status == 2">
                        <el-tag type="info">退款成功</el-tag>
                    </span>
                    <span v-if="orderDetail.status == 3">
                        <el-tag type="success">退款失败</el-tag>
                    </span>
                </el-col>
            </el-row>
            <el-row justify="space-evenly" :gutter="25" class="row-bg">
                <el-col :span="4" class="col-bg">渠道编码：</el-col>
                <el-col :span="8" class="col-bg">{{ orderDetail.payment_channel_code }}</el-col>
                <el-col :span="4" class="col-bg">支付方式：</el-col>
                <el-col :span="8" class="col-bg">{{ orderDetail.payment_method_code }}</el-col>
            </el-row>
            <el-row justify="space-evenly" :gutter="25" class="row-bg">
                <el-col :span="4" class="col-bg border-bottom">支付设备：</el-col>
                <el-col :span="8" class="col-bg border-bottom">{{ orderDetail.serial_number }}</el-col>
                <el-col :span="4" class="col-bg border-bottom">创建时间：</el-col>
                <el-col :span="8" class="col-bg border-bottom">{{ timeFormat(orderDetail.created_at, 'datetime') }}
                </el-col>
            </el-row>
        </div>
        <template #footer>
            <el-button @click="close">关闭</el-button>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue'
import { Search } from '@element-plus/icons-vue'
import cTable from '@/components/CustomTable'
import { timeFormat } from '@/func/time'

const { proxy } = getCurrentInstance()

const isShowDetail = ref(false)
const skeleton = ref(true)
const tableRef = ref(null)
const orderDetail = ref(null)
const tableDataUrl = proxy.$api.order.refund.list
const searchFrom = ref({
    refund_no: '',
    trade_no: ''
})

const search = () => {
    tableRef.value.search(searchFrom.value)
}

const details = (row) => {
    isShowDetail.value = true
    proxy.$api.order.refund.details({ id: row.id }).then(r => {
        if (r.status == 200) {
            orderDetail.value = r.data
            skeleton.value = false
        }
    })
}

const close = () => {
    isShowDetail.value = false
    skeleton.value = true
    orderDetail.value = null
}
</script>

<style lang="less">
@import './details.less';
</style>